import {Routes} from '@angular/router';
import {AppComponent} from "./app.component";
import {HomeComponent} from "./front-end/home/home.component";
import DashboardComponent from "./dashboard/dashboard.component";
import {ContactComponent} from "./front-end/contact/contact.component";
import {loggedInGuard} from "./shared/guards/logged-in.guard";
import {NotFoundComponent} from "./dashboard/not-found/not-found.component";
import ShopPageComponent from "./front-end/shop-page/shop-page.component";
import {pageResolver} from "./front-end/static-page/page.resolver";
import HomePageComponent from "./front-end/home-page/home-page.component";
import {homeDataResolver} from "./front-end/home-page/home-data.resolver";
import {contactResolver} from "./front-end/contact/contact.resolver";
import {sellerGuard} from "./shared/guards/seller.guard";

export const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    children: [

      {
        path: '',
        component: HomeComponent,
        children: [
          {path: '', loadComponent: () => import('./front-end/home-page/home-page.component'), resolve: {data: homeDataResolver}},
          {path: 'not-found', component: NotFoundComponent,},
          {path: 'contact-us', component: ContactComponent, resolve: [contactResolver]},
          {path: 'shop', component: ShopPageComponent},
          {path: 'shop/checkout', loadComponent: () => import('./front-end/shop-checkout/shop-checkout.component')},
          {path: 'shop/wishlist', loadComponent: () => import('./front-end/shop-wishlist/shop-wishlist.component')},
          {path: 'shop/product/:slug', loadComponent: () => import('./front-end/shop-product-page/shop-product-page.component')},
          {path: 'shop/category/:slug', loadComponent: () => import('./front-end/shop-page/shop-page.component')},
          {path: 'shop/order-status/:id', loadComponent: () => import('./front-end/shop-thank-you/shop-thank-you.component')},
          {path: 'page/:slug', loadComponent: () => import('./front-end/static-page/static-page.component'), resolve: {page: pageResolver}},
        ]
      },
    ]
  },

  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [loggedInGuard],
    canDeactivate: [sellerGuard],
    children: [
      {path: '', loadComponent: () => import('./dashboard/summary-dashboard/summary-dashboard.component')},
      {path: 'aff-dashboard', loadComponent: () => import('./dashboard/affiliate-section/affiliate-dash/affiliate-dash.component')},
      {path: 'change-password', loadComponent: () => import('./dashboard/change-password/change-password.component')},
      {path: 'bank-accounts', loadComponent: () => import('./dashboard/bank-accounts/bank-accounts.component')},
      {path: 'upgrade-account', loadComponent: () => import('./dashboard/upgrade-account/upgrade-account.component')},
      {path: 'autopool-tree', loadComponent: () => import('./dashboard/autopool-tree/autopool-tree.component')},
      {path: 'downline-team', loadComponent: () => import('./dashboard/downline-team/downline-team.component')},
      {path: 'downline-tree', loadComponent: () => import('./dashboard/downline-team/downline-tree/downline-tree.component')},
      //
      {path: 'referral-team', loadComponent: () => import('./dashboard/referral-team/referral-team.component')},
      {path: 'wallets', loadComponent: () => import('./dashboard/wallet/wallet.component')},
      {path: 'wallet-request', loadComponent: () => import('./dashboard/wallet-request/wallet-request.component')},
      {
        path: 'pay-to-vendor',
        loadComponent: () => import('./dashboard/pay-to-vendor/pay-to-vendor.component'),
      },
      {path: 'my-orders', loadComponent: () => import('./dashboard/customer-orders/customer-orders.component')},
      {path: 'kyc', loadComponent: () => import('./dashboard/kyc/kyc.component')},
      {path: 'tickets', loadComponent: () => import('./dashboard/tickets/tickets.component')},
      {path: 'my-profile', loadComponent: () => import('./dashboard/my-profile/my-profile.component')},
      {path: 'offline-vendor', loadComponent: () => import('./dashboard/offline-sellers/offline-sellers.component')},
      {path: 'bank-transfers', loadComponent: () => import('./dashboard/bank-transfer-requests/bank-transfer-requests.component')},
      {path: 'recharges', loadComponent: () => import('./dashboard/recharge/recharge.component')},
      {path: 'upgrade-user-account', loadComponent: () => import('./dashboard/admin/upgrade-account/upgrade-account.component')},
      //shop
      {path: 'shop/attributes', loadComponent: () => import('./dashboard/shop/manage-attributes/manage-attributes.component')},
      {path: 'shop/products', loadComponent: () => import('./dashboard/shop/manage-products/manage-products.component')},
      {path: 'shop/orders', loadComponent: () => import('./dashboard/shop/manage-orders/manage-orders.component')},
      {path: 'shop/shipping-address', loadComponent: () => import('./dashboard/shop/shipping-address/shipping-address.component')},
      {path: 'receive-payment', loadComponent: () => import('././dashboard/admin/receive-payment/receive-payment.component')},

      //admin routes only

      {
        path: 'admin',
        canActivate: [loggedInGuard],
        loadComponent: () => import('./dashboard/admin/admin-dashboard/admin-dashboard.component'),
        children: [
          {path: 'upgrade-user-account', loadComponent: () => import('./dashboard/admin/upgrade-account/upgrade-account.component')},
          {path: 'wallet-requests', loadComponent: () => import('./dashboard/admin/wallet-requests-admin/wallet-requests-admin.component')},
          {path: 'tds-settings', loadComponent: () => import('./dashboard/admin/tds-settings/tds-settings.component')},
          {path: 'contact-settings', loadComponent: () => import('./dashboard/admin/contact-settings/contact-settings.component')},
          {path: 'commission-settings', loadComponent: () => import('./dashboard/admin/referral-commission-settings/referral-commission-settings.component')},
          {path: 'package-settings', loadComponent: () => import('./dashboard/admin/packages-settings/packages-settings.component')},
          {path: 'manage-reviews', loadComponent: () => import('./dashboard/admin/all-reviews/all-reviews.component')},
          {path: 'manage-banks', loadComponent: () => import('./dashboard/admin/all-banks/all-banks.component')},
          {path: 'manage-kyc/:type', loadComponent: () => import('./dashboard/admin/all-kycs/all-kycs.component')},
          {path: 'manage-users', loadComponent: () => import('./dashboard/admin/all-users/all-users.component')},
          {path: 'manage-tickets', loadComponent: () => import('./dashboard/admin/all-tickets/all-tickets.component')},
          {path: 'manage-shop-categories', loadComponent: () => import('./dashboard/admin/all-shop-categories/all-shop-categories.component')},
          {path: 'manage-bank-transfers', loadComponent: () => import('./dashboard/admin/all-bank-transfers/all-bank-transfers.component')},
          {path: 'manage-news', loadComponent: () => import('./dashboard/admin/all-news/all-news.component')},
          {path: 'manage-pages', loadComponent: () => import('./dashboard/admin/all-pages/all-pages.component')},
          {path: 'manage-recharges', loadComponent: () => import('./dashboard/admin/all-recharges/all-recharges.component')},
          {path: 'manage-homepage', loadComponent: () => import('./dashboard/admin/home-page-settings/home-page-settings.component')},
          {path: 'user/wallets/:id/:type', loadComponent: () => import('././dashboard/admin/all-users/user-wallet/user-wallet.component')},
        ]
      },

    ]
  },


];
