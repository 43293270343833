import {APP_INITIALIZER, ApplicationConfig, ErrorHandler, forwardRef, importProvidersFrom, inject, PLATFORM_ID} from '@angular/core';
import {ActivatedRouteSnapshot, BaseRouteReuseStrategy, provideRouter, Route, Router, ROUTER_CONFIGURATION, RouteReuseStrategy, withComponentInputBinding} from '@angular/router';

import {routes} from './app.routes';
import {provideClientHydration} from '@angular/platform-browser';
import {DialogService} from "primeng/dynamicdialog";

import {provideErrorTailorConfig} from "@ngneat/error-tailor";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NgEventBus} from "ng-event-bus";
import {ApiService} from "./xstudioz/services/api.service";
import {HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withFetch, withInterceptors} from "@angular/common/http";
import {ToastInterceptor,} from "./xstudioz/interceptors/toast.interceptor";
import {ConfirmationService, MessageService} from "primeng/api";
import {prefixInterceptor} from "./xstudioz/interceptors/prefix.interceptor";
import {provideTinymce} from "ngx-tinymce";
import {catchError, finalize, firstValueFrom, merge, mergeAll, mergeWith, of, tap, throwError} from "rxjs";
import {AuthService} from "./xstudioz/services/auth.service";

import {IMAGE_LOADER, ImageLoaderConfig, provideImageKitLoader} from "@angular/common";
import {AppSettingsService} from "./shared/services/app-settings.service";
import {provideNgxWebstorage, withLocalStorage, withNgxWebstorageConfig, withSessionStorage} from "ngx-webstorage";
import {customShareButton, provideShareButtonsOptions, withConfig} from "ngx-sharebuttons";
import {shareIcons} from "ngx-sharebuttons/icons";
import {providePrimeNG} from 'primeng/config';
import Aura from '@primeng/themes/aura';

function initializeApp(api: ApiService, authService: AuthService, router: Router) {
  let pid = inject(PLATFORM_ID)
  if (pid !== 'server') {
    let checkUser = api.get('check-user', {});
    return () => api.get('csrf-cookie')
      .pipe(
        mergeWith(checkUser),
        tap((r) => {
          authService.saveUser(r);
        }),
        catchError((e,) => {
          return of(null);
        })
      )
  }
  return () => true;
}


export const appConfig: ApplicationConfig = {
  providers: [
    providePrimeNG({
      theme: {
        preset: Aura,
        options: {
          darkModeSelector: 'class',
        }
      }
    }),
    provideNgxWebstorage(
      withNgxWebstorageConfig({separator: ':', caseSensitive: true}),
      withLocalStorage(),
      withSessionStorage()
    ),
    provideShareButtonsOptions(
      shareIcons()
    ),
    provideImageKitLoader('https://ik.imagekit.io/minhajul/'),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [ApiService, AuthService, AppSettingsService, Router],
    },
    {
      provide: ROUTER_CONFIGURATION,
      useValue: {
        scrollPositionRestoration: 'enabled',
      }
    },
    MessageService,
    DialogService,
    NgEventBus,
    ConfirmationService,
    provideTinymce({
      baseURL: '//cdnjs.cloudflare.com/ajax/libs/tinymce/7.0.0/',
      config: {
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar:
          'undo redo | formatselect | bold italic backcolor | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | removeformat | help'
      }
    }),
    provideRouter(routes, withComponentInputBinding()),
    provideClientHydration(),
    importProvidersFrom([
      BrowserAnimationsModule,
      NgEventBus,
      MessageService,
    ]),
    provideHttpClient(
      withFetch(),
      withInterceptors([
        prefixInterceptor,
        ToastInterceptor
      ])),
    provideErrorTailorConfig({
      errors: {
        useValue: {
          required: 'This field is required',
          minlength: ({requiredLength, actualLength}) => `Minimum ${requiredLength} characters required`,
          invalidAddress: error => `Address isn't valid`,
          noMatch: error => `Passwords dont match`,
          age18: error => `Age should be greater than 18`,
          ageGreaterThan18: error => `Age should be greater than 18`,
          invalidReferredBy: error => `Invalid Referrer ID`,
          already_upgraded: error => `User Already Upgraded`,
          user_not_found: error => `User Not Found`,
          invalid_id: error => `Your unique id is invalid`,
          exists: error => `Already in use`,
          email: error => `Invalid Email Address`,
          invalid_user: error => `Invalid User ID`,
        }
      }
    }),
    provideShareButtonsOptions(
      // Override default config
      withConfig({
        debug: true
      }),
      // Load the default share icons set (optional)
      shareIcons(),
    ),
  ],

};
